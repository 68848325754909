import React from "react";
import NotificationSystem from "react-notification-system";
import { subscribe, events } from "./../services/pubsubService";
import { translate } from "./../services/translationService";
import { style } from "./../variables/Variables";

export default class Notifications extends React.Component {
  constructor() {
    super();

    this.state = {
      _notificationSystem: null
    };

    this.showNotification = this.showNotification.bind(this);

    subscribe(events.SHOW_NOTIFICATION, this.showNotification);
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
  }

  showNotification(event, data) {
    const message = translate(data.message);
    const level = data.type || "success";
    const notification = this.state._notificationSystem;

    notification.addNotification({
      message,
      level,
      position: "tc"
    });
  }

  render() {
    return (
      <div>
        <NotificationSystem ref="notificationSystem" style={style} />
      </div>
    );
  }
}
