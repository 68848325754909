import React from "react";
import dayjs from "dayjs";
import { Button, DatePicker, Select } from 'antd/dist/antd';
import Translation from '../../components/Translation';
const { RangePicker } = DatePicker;

const dateFormat = "DD/MM/YYYY";
const placeholder = ["Datum od", "Datum do"];

const timeUnits =  [
    [<Translation id="Year"/>, "year"],
    [<Translation id="Month"/>, "month"],
    [<Translation id="Day"/>, "day"],
    [<Translation id="Hour"/>, "hour"],
    [<Translation id="Minute"/>, "minute"]
]

const DateFilter = ({generateXls, setTimeUnit, setDates, timeUnit}) => {
    const today = new Date();
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", margin: "20px 0px"}}>
            <div>
                <p style={{display: "inline", marginBottom: "0px", marginRight: "10px"}}>Filter:</p>
                <RangePicker
                    //defaultValue={[dayjs(new Date()), dayjs(new Date())]}
                    defaultValue={[dayjs(new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        1
                    )), dayjs(new Date())]}
                    format={dateFormat}
                    placeholder={placeholder}
                    onChange={(dates) => {
                        if(dates) {
                            dates[0] = dates[0].startOf('day');
                            dates[1] = dates[1].endOf('day');
                        }
                        setDates(dates);
                    }}
                />
            </div>
            <div style={{marginLeft: "10px"}}>
                <Select defaultValue={timeUnit} 
                    onChange={(unit) => setTimeUnit(unit)} 
                    style={{width: 120}}>
                        {timeUnits.map((unit, index) => {
                            if(!unit) return <div></div>;
                            return <Select.Option key={index} value={unit[1]}>{unit[0]}</Select.Option>
                        })}
                </Select>
            </div>
            <div style={{marginLeft: "10px"}}>
                <Button 
                    onClick={() => {
                        generateXls().then(() => {
                            //ZAVRSIO SE EXPORT :D
                            
                        });
                    }}
                >
                    XLS Export
                </Button>
            </div>

        </div>
    )
}

export default DateFilter;