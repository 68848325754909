import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import authService from "./../../services/authService";
import { Redirect } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd/dist/antd";

class ResetPasswordRequestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestPending: false,
      valid: true,
      loading: true
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async validateUUID() {
    const uuid = this.props.computedMatch.params.uuid;
    return authService.getResetPasswordRequest(uuid);
  }

  componentDidMount() {
    this.validateUUID()
      .then(() => { this.setState({valid: true, loading: false})})
      .catch(err => this.setState({valid: false, loading: false}));
  }

  onSubmit(e) {
    e.preventDefault();
    const [password, repPassword] = [e.target.elements[0].value.trim(), e.target.elements[1].value.trim()];
    if(password.length < 8) {
      alert('Password has to be at least 8 characters long.');
      return;
    }
    if(password !== repPassword) {
      alert('Passwords do not match.');
      return;
    }
    const data = {
      password: password,
      uuid: this.props.computedMatch.params.uuid
    }
    this.setState({requestPending: true});
    authService
      .resetPassword(data)
      .then((res) => {
        alert(`Your password has been updated.`);
        this.setState({requestPending: false});
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
        alert('Error.');
        this.setState({requestPending: false});
      });
  }

  render() {
    return this.state.valid ? (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={this.onSubmit}>
              <Card
                textCenter
                title="Reset Password"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>New Password</ControlLabel>
                      <FormControl placeholder="Enter New Password" type="password" />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Repeat New Password</ControlLabel>
                      <FormControl placeholder="Repeat New Password" type="password" />
                    </FormGroup>
                  </div>
                }
                legend={
                  this.state.requestPending ? 
                    <Button type="submit" bsStyle="info" fill wd disabled={true}>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </Button>
                  :
                    <Button type="submit" bsStyle="info" fill wd disabled={this.state.loading}>
                      Reset Password
                    </Button>
                } 
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    ) 
    : <Redirect to="/login"/>;
  }
}

export default ResetPasswordRequestPage;
