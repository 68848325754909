import React, {useState} from "react";
import { Line } from "react-chartjs-2";
import scaleGraphOptions from "./scaleGraphOptions";
import DateFilter from "./DateFilter";
import ScaleMap from "./ScaleMap";
import {utils, writeFile} from "xlsx";
import dayjs from "dayjs";
import { render } from "react-dom";

const ScaleGraph = ({ measurements, setDateRange, test, forecastRef }) => {
  const [timeUnit, setTimeUnit] = useState("day");
  
  const generateXls = async () => {
    if(measurements.length === 0) return;
    const content = 
    <tbody>
      <tr>
        <th>NET Weight</th>
        <th>TARE Weight</th>
        <th>Daily Income</th>
        <th>GSM Strength</th>
        <th>Battery Strength</th>
        <th>Humidity</th>
        <th>External Temperature</th>
        <th>Internal Temperature</th>
        <th>Date</th>
      </tr>
      {
        measurements.map((m, i) => {
          const temps = m.temperatures;

          const internal = temps.filter((t) => {
            return t.senzorType === "internal";
          });

          const external = temps.filter((t) => {
            return t.senzorType === "external";
          });

          return <tr key={i}>
            <td>{m.netWeight ? m.netWeight : "N/A"}</td>
            <td>{m.tareWeight ? m.tareWeight : "N/A"}</td>
            <td>{m.dailyIncome ? m.dailyIncome : "N/A"}</td>
            <td>{m.gsmStrength ? m.gsmStrength : "N/A"}</td>
            <td>{m.batteryStrength ? m.batteryStrength : "N/A"}</td>
            <td>{m.humidity ? m.humidity : "N/A"}</td>
            <td>{external.length > 0 ? external[0].value + "°C" : "N/A"}</td>
            <td>{internal.length > 0 ? internal[0].value + "°C" : "N/A"}</td>
            <td>{m.datetime ? dayjs(m.datetime).format('DD/MM/YYYY') : "N/A"}</td>
          </tr>
        })
      }
    </tbody>
    const tbl = document.createElement('table');
    render(content, tbl);

    const wb = utils.table_to_book(tbl);
    writeFile(wb, "report.xlsx");
  }

  let data, location;
  if(!test) {
    const labels = measurements.map(m => new Date(m.datetime));
    const netWeight = measurements.map(m => m.netWeight);
    const tareWeight = measurements.map(m => m.tareWeight);
    const dailyIncome = measurements.map(m => m.dailyIncome);
  
    data = {
      labels: labels,
      datasets: [
        {
          label: "NET Weight",
          backgroundColor: "#ff0000",
          borderColor: "#ff0000",
          fill: false,
          data: netWeight,
        },
        {
          label: "TARE Weight",
          backgroundColor: "#00ff00",
          borderColor: "#00ff00",
          fill: false,
          data: tareWeight,
          hidden: false,
        },
        {
          label: "Daily Income",
          backgroundColor: "#0000ff",
          borderColor: "#0000ff",
          fill: false,
          data: dailyIncome,
          hidden: true,
          showLine: false
        },
      ]
    };
  } 
  //Test Scales
  else {
    const lastMeasurement = measurements.sort((a, b) => b - a)[0];
    location = lastMeasurement && lastMeasurement.lat && lastMeasurement.long ? 
    {lat: lastMeasurement.lat, long: lastMeasurement.long} : null;

    const labels = measurements.map(m => new Date(m.datetime));
    const weightflt = measurements.map(m => m.weightflt);
    const temp = measurements.map(m => m.temp);
    const rh = measurements.map(m => m.rh);
    const bat = measurements.map(m => m.bat);
    data = {
      labels: labels,
      datasets: [
        {
          label: "Weight FLT",
          backgroundColor: "#ff0000",
          borderColor: "#ff0000",
          fill: false,
          data: weightflt
        },
        {
          label: "Relative Humidity",
          backgroundColor: "#00ff00",
          borderColor: "#00ff00",
          fill: false,
          data: rh,
        },
        {
          label: "Temperature",
          backgroundColor: "#0000ff",
          borderColor: "#0000ff",
          fill: false,
          data: temp,
        },
        {
          label: "Battery",
          backgroundColor: "#ff00ff",
          borderColor: "#ff00ff",
          fill: false,
          data: bat,
        },
      ]
    };
  }

  return (
    <div>
      <Line data={data} options={scaleGraphOptions(measurements, test, timeUnit)} />
      <DateFilter generateXls={generateXls} timeUnit={timeUnit} setTimeUnit={(unit) => {setTimeUnit(unit)}} setDates={(dates) => {setDateRange(dates)}}/>
      <ScaleMap forecastRef={forecastRef} location={location}/>
    </div>
  );
};

export default ScaleGraph;
