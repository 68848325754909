import BaseModel from "./baseModel";

export default class UserModel extends BaseModel {
  constructor(obj) {
    super(obj);

    this.id = obj && obj.id ? obj.id : null;
    this.address = obj && obj.address ? obj.address : "";
    this.city = obj && obj.city ? obj.city : "";
    this.country = obj && obj.country ? obj.country : "";
    this.email = obj && obj.email ? obj.email : "";
    this.language = obj && obj.language ? obj.language : "en";
    this.name = obj && obj.name ? obj.name : "";
    this.phone = obj && obj.phone ? obj.phone : "";
    this.zip = obj && obj.zip ? obj.zip : "";
  }

  removeUneditableProperties() {
    delete this.passwordHash;
    delete this.type;
    delete this.email;
    delete this.id;

    return this;
  }
}
