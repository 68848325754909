import Page404 from "./layouts/Errors/404";
import Dashboard from "views/Dashboard/Dashboard";
import UserProfile from "./views/User/profile/UserProfile";
import ChangeLanguage from "./views/User/settings/changeLanguage/ChangeLanguage";
import ChangePassword from "./views/User/settings/changePassword/ChangePassword";
import ScaleDetailsContainer from "./views/Scales/ScaleDetailsContainer";
import LoginPage from "./views/Pages/LoginPage";
import ResetPasswordRequestPage from './views/Pages/ResetPasswordRequestPage';
import ResetPasswordPage from './views/Pages/ResetPasswordPage';

export default {
  login: {
    path: "/login",
    name: "Login",
    exact: true,
    layout: "login",
    authorized: false,
    component: LoginPage
  },
  resetpasswordrequest: {
    path: "/reset-password",
    name: "Reset Password Request",
    exact: true,
    layout: "login",
    authorized: false,
    component: ResetPasswordRequestPage
  },
  resetpassword: {
    path: "/reset-password/:uuid",
    name: "Reset Password",
    exact: true,
    layout: "login",
    authorized: false,
    component: ResetPasswordPage
  },
  dashboard: {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    layout: "standard",
    authorized: true,
    icon: "pe-7s-graph",
    component: Dashboard
  },
  profile: {
    path: "/user/profile",
    name: "Profile",
    exact: true,
    mini: "P",
    layout: "standard",
    authorized: true,
    component: UserProfile
  },
  language: {
    path: "/user/language",
    name: "Language",
    exact: true,
    mini: "L",
    layout: "standard",
    authorized: true,
    component: ChangeLanguage
  },
  password: {
    path: "/user/password",
    name: "Password",
    exact: true,
    mini: "P",
    layout: "standard",
    authorized: true,
    component: ChangePassword
  },
  scaleDetails: {
    path: "/scale/:id",
    name: "Scale details",
    exact: true,
    layout: "standard",
    authorized: true,
    component: ScaleDetailsContainer
  },
  root: {
    path: "/",
    name: "Dashboard",
    exact: true,
    layout: "standard",
    icon: "pe-7s-graph",
    authorized: true,
    component: Dashboard
  },
  unknown: {
    path: "*",
    layout: "blank",
    component: Page404
  }
};
