import actions from "./../actionTypeRegistry";

const setUserAction = user => {
  return {
    type: actions.user.USER_LOGGED_IN,
    payload: user
  };
};

const logoutUserAction = user => {
  return {
    type: actions.user.USER_LOGGED_OUT,
    payload: user
  };
};

export { setUserAction, logoutUserAction };
