import React from "react";
import { Link } from "react-router-dom";
import Translation from "./../../../../components/Translation";
import LanguageSelector from "./../../../../components/LanguageSelector";
import { connect } from "react-redux";
import userService from "./../../userService";

const ChangeLanguage = props => {
  let selected = null;

  const onSelectHandler = option => {
    selected = option;
  };

  const submit = () => {
    userService.changeLanguage(selected.code).catch(err => {
      console.log(err);
    });
  };

  return (
    <div className="col-lg-4 col-lg-offset-4">
      <LanguageSelector
        options={props.options}
        current={props.active}
        className="offset-bottom-m"
        onSelectHandler={onSelectHandler}
      />
      <button className="btn btn-lg btn-primary" type="button" onClick={submit}>
        <Translation id="Change" />
      </button>
      <Link
        to="/user/settings"
        className="btn btn-lg btn-primary offset-left-s"
      >
        <Translation id="Cancel" />
      </Link>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    active: state.language.active,
    options: state.language.options
  };
};

export default connect(mapStateToProps)(ChangeLanguage);
// export default ChangeLanguage;
