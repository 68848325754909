import { createStore, applyMiddleware, compose } from "redux";
import combinedReducers from "./reducers/allReducers";
import { actionLogger } from "./middlewares/middlewareRegistry";
import ReduxThunk from "redux-thunk";

const combinedMiddlewares = applyMiddleware(ReduxThunk, actionLogger);
const preloadedState = window.localStorage.getItem("USER_APP_STORE");
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store;

if (preloadedState) {
  store = createStore(
    combinedReducers,
    JSON.parse(preloadedState),
    composeEnhancers(combinedMiddlewares)
  );
} else {
  store = createStore(combinedReducers, composeEnhancers(combinedMiddlewares));
}

export default store;
