import React, { useState } from "react";
import { backend as API } from "../../services";
import { translate } from "./../../services/translationService";
import { EditOutlined, LoadingOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Input } from "antd/dist/antd";
const { TextArea } = Input;

const ScaleDetails = ({ scale }) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [comment, setComment] = useState(scale && scale.comment ? scale.comment : '');
  const [name, setName] = useState(scale && scale.name ? scale.name : '');

  const saveScale = async () => {
    setLoading(true);
    await API.backend({
      method: API.methods.PUT,
      url: API.endpoints.scale,
      data: {
        id: scale.id,
        comment: comment,
        name: name
      }
    });
    setLoading(false);
  }

  return (<React.Fragment>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
      <h3>Scale details</h3>
      <Button 
        style={{width: "50px", height: "50px"}}
        disabled={loading}
        onClick={async () => {
          if(editMode) await saveScale();
          setEditMode(!editMode);
        }}
      >{
        loading ? <LoadingOutlined /> : (editMode ? <CheckOutlined /> : <EditOutlined/>)
      }</Button>
    </div>
    <br />
    <p>Name: {
      !loading && editMode ? (
      <Input
        defaultValue={name}
        onChange={(e) => {setName(e.target.value)}}
        maxLength={32}    
      />
      ) : (name || "N/A")
    }
      
    </p>
    <p>IMEI: {scale.imei || "N/A"}</p>
    <p>Location: {scale.location || "N/A"}</p>
    {
      !loading && editMode ? (
        <TextArea
          className="full-width"
          rows={10}
          placeholder={translate("Your comment")}
          defaultValue={comment}
          onChange={(e) => setComment(e.target.value)}
          maxLength={256}
          //value={scale.comment}
        />
      ) : (
        <p>
          {comment || "N/A"}
        </p>
      ) 
    }

  </React.Fragment>)
};

export default ScaleDetails;
