import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";
import ScaleDetailsBox from "./ScaleDetailsBox";
import { connect } from "react-redux";
import { fetchScales } from "./../Scales/scaleService";

class Dashboard extends Component {
  componentDidMount() {
    this.props.fetchScales();
  }

  render() {
    if (!this.props.scales.length) {
      return null;
    }

    const scalesInBox = this.props.scales.map(s => {
      return (
        <Col lg={4} sm={6} key={s.id}>
          <ScaleDetailsBox
            scaleName={s.name}
            scaleImei={s.imei}
            test={s.test}
            link={`/scale/${s.id}`}
          />
        </Col>
      );
    });

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>{scalesInBox}</Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    scales: state.scales.items
  };
};

const mapDispatchToProps = {
  fetchScales
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
