import moment from "moment";

export default getTooltip;

function getTooltip(measurement, test) {
  //longitude & latitude
  if(test) {
    return `
FW: ${measurement.fw ? measurement.fw : "N/A"}
Temperature: ${measurement.temp ? measurement.temp : "N/A"}
Relative Humidity: ${measurement.rh ? measurement.rh + "%" : "N/A"}
Weight: ${measurement.weight ? measurement.weight + " kg" : "N/A"}
Weight FLT: ${measurement.weight_flt ? measurement.weightflt + " kg" : "N/A"}
Battery Voltage: ${measurement.bat ? measurement.bat + " V" : "N/A"}
Altitude: ${measurement.alt ? measurement.alt : "N/A"}
Date: ${new moment(measurement.datetime).utcOffset('+0100').format("DD/MM/YYYY HH:mm")}  
`;
  } else {
    const ext = measurement.temperatures.filter(t => t.senzorType === "external");
    const int = measurement.temperatures.filter(t => t.senzorType === "internal");
    return `
NET Weight: ${measurement.netWeight} kg
TARE Weight: ${measurement.tareWeight} kg
Daily Income: ${measurement.dailyIncome} kg
GSM Strength: ${measurement.gsmStrength}/31
Battery Strength: ${measurement.batteryStrength}/5
Humidity: ${measurement.humidity ? measurement.humidity + "%" : "N/A"}
Temperatures:
  External: ${
    ext && ext[0] && !Number.isNaN(ext[0].value) ? ext[0].value + "°C" : "N/A"
  }
  Internal: ${
    int && int[0] && !Number.isNaN(int[0].value) ? int[0].value + "°C" : "N/A"
  }

Date: ${new moment(measurement.datetime).utcOffset('+0100').format("DD/MM/YYYY HH:mm")}
`;
  }

}
