import React from "react";
import { withFormsy } from "formsy-react";
import Translation from "./Translation";

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.selectHandler = this.selectHandler.bind(this);
  }

  selectHandler(event) {
    this.props.setValue(event.target.value);
  }

  render() {
    return (
      <div className="form-group">
        {(() => {
          if (this.props.label) {
            return (
              <label htmlFor={this.props.name}>
                <Translation id={this.props.label} />
              </label>
            );
          }

          return null;
        })()}
        <select
          value={this.props.getValue()}
          name={this.props.name}
          className="form-control"
          onChange={this.selectHandler}
        >
          {(() => {
            if (this.props.getValue() === "") {
              return (
                <option value="">
                  <Translation id={this.props.defaultdisplay} />
                </option>
              );
            }
            return null;
          })()}
          {this.props.options.map(option => {
            return (
              <option
                key={option[this.props.selectableProp]}
                value={option[this.props.selectableProp]}
              >
                {option[this.props.displayProp]}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

export default withFormsy(Select);
