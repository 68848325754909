import DropDown from "./DropDown";
import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

class LanguageSelector extends React.Component {
  constructor(props) {
    super();

    this.state = {
      selected: props.current
    };
  }

  optionSelectedHandler = newSelectedOption => {
    this.setState({
      selected: newSelectedOption
    });
    this.props.onSelectHandler(newSelectedOption);
  };

  render() {
    return (
      <div className={classnames("language-selector", this.props.className)}>
        <DropDown
          bsSize="large"
          displayProp="name"
          selectedOption={this.state.selected}
          options={this.props.options}
          onOptionSelected={this.optionSelectedHandler}
        />
      </div>
    );
  }
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
  current: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectHandler: PropTypes.func.isRequired
};

export default LanguageSelector;
