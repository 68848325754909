import React, { useState, useEffect } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { Marker, MapContainer, TileLayer, useMapEvents } from 'react-leaflet'
import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { LoadingOutlined } from "@ant-design/icons";
import Axios from "axios";
import dayjs from "dayjs";
import forecastIcons from '../../data/forecast-icons.json';
import { translate } from "../../services/translationService";

const ScaleMap = (props) => {
  useEffect(() => {
    if(props.location) {
      loadForecast(props.location, props.forecastRef);
    }
  }, [])
  return <MapWidget forecastRef={props.forecastRef.current} location={props.location} />;
}

const loadForecast = async (location, forecastRef) => {
  render(<LoadingOutlined />, forecastRef);
  fetchForecast(location.lat, location.long).then((data) => {
    unmountComponentAtNode(forecastRef);
    render(data, forecastRef);
  });
}

const fetchForecast = async (lat, long) => {
  const weatherAPI = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${long}&timezone=UTC&forecast_days=5&daily=temperature_2m_max,temperature_2m_min,weather_code`;
  try {
    const res = (await Axios.get(weatherAPI)).data;
    const data = {
      maxTemp: res.daily.temperature_2m_max.map((t) => t.toFixed()),
      minTemp: res.daily.temperature_2m_min.map((t) => t.toFixed()),
      day: res.daily.time.map((d) => numToWeekday(dayjs(d).weekday())),
      weatherIcon: res.daily.weather_code.map((code) => weatherCodeToIcon(code)),
    }
    const forecast = (<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
      {[0, 1, 2, 3, 4].map((i) => {
          return <ForecastInstance key={i} data={{
            maxTemp: data.maxTemp[i],
            minTemp: data.minTemp[i],
            day: data.day[i],
            weatherIcon: data.weatherIcon[i]
          }}/>
        })
      }
    </div>)
    return forecast;
  } catch(err) {
    return <p>{translate("Select Location")}</p>;
  }
}

const weatherCodeToIcon = (code) => {
  try {
    return forecastIcons[code].day.image;
  } catch(err) {
    return forecastIcons[0].day.image;
  }
  
}

const numToWeekday = (num) => {
  switch (num) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default: 
      return "N/A";
  }
} 

const ForecastInstance = (props) => {
  return <div style={{display: "flex", flexDirection: "column", alignItems:  "center", overflow: "hidden"}}>
    <p style={{margin: "0"}}>{translate(props.data.day)}</p>
    <img src={props.data.weatherIcon}  style={{width: "60px", height: "60px"}}></img>
    <div style={{display: "flex", flexDirection: "row", alignItems:"center", justifyContent: "space-between"}}>
      <p style={{fontSize: "14px",  marginRight: "3px"}}>{props.data.maxTemp}°C</p>
      <p style={{color: "gray", fontSize: "14px"}}>{props.data.minTemp}°C</p>
    </div>
  </div>
}

const LocationMarker = (props) => {
  const [position, setPosition] = useState(null);
  useEffect(() => {
    if(props.location) {
      props.location.lng = props.location.long;
      setPosition(props.location);
    }
  }, []);
  const map = useMapEvents({
    load() {
      map.locate();
    },
    click(e) {
      loadForecast({lat: e.latlng.lat, long: e.latlng.lng}, props.forecastRef)
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
    locationfound(e) {
      if(position === null) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
      }
    }
  });

  return position === null ? null : (
    <Marker position={position} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})} />
  )
}

const MapWidget = (props) => {
  const position = props.location ? props.location : {lat: 44.787197, lng: 20.457273}
  return  <MapContainer center={position} zoom={8} scrollWheelZoom={true}>
    <TileLayer
      url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <LocationMarker forecastRef={props.forecastRef} location={props.location}/>
  </MapContainer>
};

export default ScaleMap;
