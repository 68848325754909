import actionTypeRegistry from "../actionTypeRegistry";

const setLocalizationAction = (languageCode) => {
  return {
    type: actionTypeRegistry.localization.SET_LOCALIZATION,
    payload: languageCode
  };
};

export {
  setLocalizationAction
};
