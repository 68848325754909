import { backend as API } from "./";
import userService from "./../views/User/userService";
import persistanceService from "./persistanceService";

const ACCESS_TOKEN = "ACCESS_TOKEN";
const REFRESH_TOKEN = "REFRESH_TOKEN";

export default {
  login,
  logout,
  tryAutoLogin,
  createResetPasswordRequest,
  getResetPasswordRequest,
  resetPassword
};

function login(credentials, rememberMe) {
  return authenticate(credentials, rememberMe).then(fetchInitialData);
}

function logout() {
  persistanceService.remove(ACCESS_TOKEN);
  persistanceService.remove(REFRESH_TOKEN);
  // just to be sure that nothing is accidentaly leaked
  window.location.reload(true);
}

async function tryAutoLogin() {
  const accessToken = persistanceService.get(ACCESS_TOKEN);

  if (accessToken) {
    return fetchInitialData();
  }

  return;
}

function authenticate(credentials) {
  return API.backend({
    method: API.methods.POST,
    data: credentials,
    url: API.endpoints.login
  }).then(res => {
    if (!res) {
      return;
    }

    persistanceService.set(ACCESS_TOKEN, res.accessToken);
    persistanceService.set(REFRESH_TOKEN, res.refreshToken);
  });
}

function fetchInitialData() {
  return userService.getUser();
}

async function createResetPasswordRequest(credentials) {
  return API.backend({
    method: API.methods.POST,
    data: credentials,
    url: API.endpoints.createResetPasswordRequest
  });
}

async function getResetPasswordRequest(uuid) {
  return API.backend({
    method: API.methods.GET,
    url: API.endpoints.resetPassword + uuid
  })
}

async function resetPassword(data) {
  return API.backend({
    method: API.methods.POST,
    data: data,
    url: API.endpoints.resetPassword,
  })
}