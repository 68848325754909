import React, { Component } from "react";
import { Link } from "react-router-dom";
import Translation from "./../../components/Translation";
import { translate } from "./../../services/translationService";

export class ScaleDetailsBox extends Component {
  render() {
    return (
      <div className="card card-stats">
        <div className="content">
          <div className="row">
            <div className="col-xs-3">
              <div className="icon-big text-center icon-warning">
                <i className={`pe-7s-server ${this.props.test ? "text-primary" : "text-success"}`} />
              </div>
            </div>
            <div className="col-xs-9">
              <div className="numbers">
                <p>{translate("Scale")}</p>
                <p>
                  <strong>
                    {this.props.scaleName || translate("Undefined name")}
                  </strong>
                </p>
                <p>
                  <small>IMEI: {this.props.scaleImei || "N/A"}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <hr />
          <div className="stats text-right">
            <Link to={this.props.link}>
              <Translation id="Details" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ScaleDetailsBox;
