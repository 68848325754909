export default {
  language: {
    SET_LANGUAGE: "SET_LANGUAGE",
    SET_LANGUAGE_OPTION: "SET_LANGUAGE_OPTION",
    SELECT_LANGUAGE_OPTION: "SELECT_LANGUAGE_OPTION"
  },
  user: {
    USER_LOGGED_IN: "USER_LOGGED_IN",
    USER_LOGGED_OUT: "USER_LOGGED_OUT"
  },
  localization: {
    SET_LOCALIZATION: "SET_LOCALIZATION"
  },
  scale: {
    SET_SCALES: "SET_SCALES"
  }
};
