export default Object.freeze({
  current: {
    validations: "isDefined",
    validationErrors: {
      isDefined: "Field is missing"
    },
    placeholder:"Enter current password",
    label:"Current password"
  },
  newPassword: {
    validations: "isDefined,minLength:8",
    validationErrors: {
      isDefined: "Field is missing",
      minLength: "Minumum 8 chars"
    },
    placeholder: "Enter new password",
    label: "New password"
  },
  confirmPassword: {
    validations: "isDefined,equalsField:newPassword",
    validationErrors: {
      isDefined: "Field is missing",
      equalsField: "Passwords don't match"
    },
    placeholder: "Confirm new password",
    label: "Repeat new password"
  }
});
