import pubsub from "pubsub-js";

const events = {
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION"
};

function publish(event, data) {
  pubsub.publish(event, data);
}

function subscribe(event, handler) {
  return pubsub.subscribe(event, handler);
}

function unsubscribe(token) {
  pubsub.unsubscribe(token);
}

export { publish, subscribe, unsubscribe, events };
