import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import authService from "./../../services/authService";
import { Link, Redirect } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd/dist/antd";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      loginOk: false,
      requestPending: false
    };

    this.onLogin = this.onLogin.bind(this);
  }

  onLogin(e) {
    e.preventDefault();
    const data = {
      email: e.target.elements[0].value,
      password: e.target.elements[1].value
    };
    this.setState({requestPending: true});
    authService
      .login(data)
      .then(() => {
        this.setState({ loginOk: true, requestPending: false });
      })
      .catch(err => {
        if (err.response && err.response.status === 400) {
          this.setState({requestPending: false});
          alert("Bad username/password");
        }
      });
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }

  render() {
    if (this.state.loginOk) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={this.onLogin}>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Email address</ControlLabel>
                      <FormControl placeholder="Enter email" type="email" />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl placeholder="Password" type="password" />
                    </FormGroup>
                  </div>
                }
                legend={
                  this.state.requestPending ? 
                  <>
                    <Button type="submit" bsStyle="info" fill wd disabled={true}>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </Button>
                    <br/>
                    <Link to="/reset-password" onClick={(e) => e.preventDefault()}>Forgot password?</Link>
                  </>

                  :
                  <>
                    <Button type="submit" bsStyle="info" fill wd>
                      Login
                    </Button>
                    <br/>
                    <Link to="/reset-password">Forgot password?</Link>
                  </>

                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;
