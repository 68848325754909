import config from "../data/configuration";
import store from "./../store/store";
import { setLanguageOptionAction } from "./../store/actions/allActions";
import { LanguageModel } from "./../models";

export default {
  init
};

export { translate };

function translate(id) {
  let translation;

  if (!id) {
    return null;
  }

  try {
    translation = store.getState().localization[id];
    console.log(store.getState());
  } catch (error) {
    console.log(`Localization not found for id: ${id}`);
  }

  return translation ? translation : id;
}

function init() {
  let current;

  try {
    current = store.getState().language.active;
  } catch (error) {}

  const options = Object.keys(config.languages)
    .map(key => config.languages[key])
    .map(l => {
      return current && current.code
        ? new LanguageModel(l.code, l.code === current.code)
        : new LanguageModel(l.code, l.default);
    });

  options.forEach(option => store.dispatch(setLanguageOptionAction(option)));
}
