// tbd: should come from API?
export default Object.freeze({
  languages: {
    en: {
      name: "English",
      code: "en",
      default: true
    },
    rs: {
      name: "Srpski",
      code: "rs",
      default: false
    },
    de: {
      name: "Deutche",
      code: "de",
      default: false
    },
    hu: {
      name: "Magyar",
      code: "hu",
      default: false
    },
    no: {
      name: "Norsk",
      code: "no",
      default: false
    },
    it: {
      name: "Italiano",
      code: "it",
      default: false
    }
  }
});
