import { backend as API } from "../../services";
import SCALE_ACTION_TYPES from "./scaleActionTypes";

const fetchScales = () => dispatch => {
  dispatch({ type: SCALE_ACTION_TYPES.FETCH_SCALES_REQUEST });

  return API.backend({
    method: API.methods.GET,
    url: API.endpoints.scale
  })
    .then(scales => {
      dispatch({
        type: SCALE_ACTION_TYPES.FETCH_SCALES_SUCCESS,
        payload: scales
      });
    })
    .catch(err => {
      dispatch({ type: SCALE_ACTION_TYPES.FETCH_SCALES_FAILURE });
    });
};

const fetchMeasurements = (scaleId, dateRange) => {
  return API.backend({
    method: API.methods.GET,
    url: API.endpoints.scale + scaleId + API.endpoints.measurement + generateQuery(dateRange[0], dateRange[1])
  });
};

const generateQuery = (startDate, endDate) => {
  if(!startDate && !endDate) return "";
  else if(startDate && endDate) return `?startDate=${startDate}&endDate=${endDate}`;
  else if(startDate) return `?startDate=${startDate}`;
  else return `?endDate=${endDate}`;
}

export { fetchScales, fetchMeasurements };
