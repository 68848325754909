/* eslint-disable no-extend-native */
export default {
  init
};

async function init() {
  extendPrototypes();
}

function extendPrototypes() {
  if (!Number.isInteger) {
    Number.isInteger =
      Number.isInteger ||
      function(value) {
        return (
          typeof value === "number" &&
          isFinite(value) &&
          Math.floor(value) === value
        );
      };
  }

  if (!String.prototype.containsOnlyDigits) {
    String.prototype.containsOnlyDigits = function() {
      return /^\d+$/.test(this);
    };
  }
}
