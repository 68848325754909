import Formsy from "formsy-react";
import React from "react";
import { Link } from "react-router-dom";
import formConfig from "./ChangePasswordFormConfiguration";
import Translation from "./../../../../components/Translation";
import Input from "./../../../../components/Input";
import userService from "./../../userService";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd/dist/antd";

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.submit = this.submit.bind(this);

    this.state = {
      errors: [],
      success: false,
      canSubmit: false,
      validationErrors: {},
      requestPending: false
    };
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  submit(event) {
    this.setState({
      requestPending: true
    });
    userService
      .changePassword(new FormData(event.target.form))
      .then(res => {
        alert("Password changed.");
        this.setState({
          success: [res],
          requestPending: false,
          loading: false,
        });
        this.refs.form.reset();
      })
      .catch(err => {
        this.setState({ loading: false, requestPending: false });
        if (err.fieldErrors) {
          this.refs.form.updateInputsWithError(err.fieldErrors);
        }
        if (err.errors) {
          this.setState({ errors: err.errors });
        }
      });
  }

  render() {
    return (
      <div className="col-lg-4 col-lg-offset-4">
        <Formsy
          ref="form"
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <Input
            validations={formConfig.current.validations}
            validationErrors={formConfig.current.validationErrors}
            name="oldPassword"
            type="password"
            placeholder={formConfig.current.placeholder}
            label={formConfig.current.label}
          />
          <Input
            validations={formConfig.newPassword.validations}
            validationErrors={formConfig.newPassword.validationErrors}
            name="newPassword"
            type="password"
            placeholder={formConfig.newPassword.placeholder}
            label={formConfig.newPassword.label}
          />
          <Input
            validations={formConfig.confirmPassword.validations}
            validationErrors={formConfig.confirmPassword.validationErrors}
            name="confirmPassword"
            type="password"
            placeholder={formConfig.confirmPassword.placeholder}
            label={formConfig.confirmPassword.label}
          />
          <button
            disabled={!this.state.canSubmit || this.state.loading || this.state.requestPending}
            className="btn btn-lg btn-primary"
            type="button"
            onClick={this.submit}
          >
            {this.state.requestPending ? 
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              :
              <Translation id="Change password" />
            }
          </button>
          <Link to="/" className="btn btn-lg btn-primary offset-left-s">
            <Translation id="Cancel" />
          </Link>
        </Formsy>
      </div>
    );
  }
}
