import PropTypes from "prop-types";
import React from "react";
import Translation from "./Translation";
import classnames from "classnames";
import { connect } from "react-redux";
import { withFormsy } from "formsy-react";
import { translate } from "./../services/translationService";

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.valueChangeHandler = this.valueChangeHandler.bind(this);

    this.state = {
      value: ""
    };
  }

  static propTypes = {
    autocomplete: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.string
  };

  static defaultProps = {
    type: "text",
    autocomplete: "on"
  };

  valueChangeHandler(event) {
    this.props.setValue(event.currentTarget.value);
    if (this.props.onChange) {
      this.props.onChange(event.currentTarget.value);
    }
  }

  render() {
    const errorMessage = this.props.getErrorMessage();

    return (
      <div className={classnames("form-group", this.props.className)}>
        {(() => {
          if (this.props.label) {
            return (
              <label className="control-label" htmlFor={this.props.name}>
                <Translation id={this.props.label} />
              </label>
            );
          }

          return null;
        })()}
        <input
          readOnly={this.props.readOnly}
          autoComplete={this.props.autocomplete}
          value={this.props.getValue() || ""}
          onChange={this.valueChangeHandler}
          name={this.props.name}
          type={this.props.type}
          className="form-control btcx-input"
          placeholder={translate(this.props.placeholder)}
        />
        {!this.props.isPristine() && (
          <span className="help-block error">
            <Translation id={errorMessage} />
          </span>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    localization: state.localization
  };
};

export default connect(
  mapStateToProps,
  null
)(withFormsy(Input));

// export default withFormsy(Input);
