import { combineReducers } from "redux";
import actions from "./../actionTypeRegistry";
import LanguageModel from "./../../models/languageModel";

const activeLanguageReducer = (state = null, action) => {
  if (action.type === actions.language.SET_LANGUAGE) {
    return action.payload;
  }

  return state;
};

const selectedLanguageReducer = (state = [], action) => {
  switch (action.type) {
    case actions.language.SELECT_LANGUAGE_OPTION:
    case actions.language.SET_LANGUAGE:
      return [...state].map(
        o => new LanguageModel(o.code, action.payload.code === o.code)
      );

    case actions.language.SET_LANGUAGE_OPTION:
      return [...state, action.payload];

    default:
      return state;
  }
};

const languageReducer = combineReducers({
  active: activeLanguageReducer,
  options: selectedLanguageReducer
});

export default languageReducer;
