import Formsy from "formsy-react";
import React from "react";
import { connect } from "react-redux";
import formConfig from "./UserProfileFormConfiguration";
import Input from "./../../../components/Input";
import Select from "./../../../components/Select";
import Translation from "./../../../components/Translation";
import countries from "./../../../data/countries";
import userService from "./../userService";
import { events, publish } from "./../../../services/pubsubService";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.submit = this.submit.bind(this);

    this.state = {
      errors: [],
      canSubmit: false,
      validationErrors: {}
    };
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  submit(event) {
    userService
      .updateUser(new FormData(event.target.form))
      .then(user => {
        publish(events.SHOW_NOTIFICATION, {
          message: "Profile updated",
          type: "success"
        });
      })
      .catch(err => {
        if (err.fieldErrors) {
          this.refs.form.updateInputsWithError(err.fieldErrors);
        }

        if (err.errors) {
          this.setState({ errors: err.errors });
        }
      });
  }

  render() {
    const user = this.props.user;
    if (!user) {
      return "ROUTE WORKS";
    }
    return (
      <div className="col-lg-4 col-lg-offset-4">
        <Formsy
          ref="form"
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <Input
            validations={formConfig.name.validations}
            validationErrors={formConfig.name.validationErrors}
            name="name"
            value={user.name}
            type="text"
            placeholder={formConfig.name.placeholder}
            label={formConfig.name.label}
          />
          <Input
            validations={formConfig.address.validations}
            validationErrors={formConfig.address.validationErrors}
            name="address"
            value={user.address}
            type="text"
            placeholder={formConfig.address.placeholder}
            label={formConfig.address.label}
          />
          <Input
            validations={formConfig.city.validations}
            validationErrors={formConfig.city.validationErrors}
            name="city"
            value={user.city}
            type="text"
            placeholder={formConfig.city.placeholder}
            label={formConfig.city.label}
          />
          <Input
            validations={formConfig.phone.validations}
            validationErrors={formConfig.phone.validationErrors}
            name="phone"
            value={user.phone}
            type="text"
            placeholder={formConfig.phone.placeholder}
            label={formConfig.phone.label}
          />
          <Input
            validations={formConfig.zip.validations}
            validationErrors={formConfig.zip.validationErrors}
            name="zip"
            value={user.zip}
            type="text"
            placeholder={formConfig.zip.placeholder}
            label={formConfig.zip.label}
          />
          <Select
            validations={formConfig.country.validations}
            validationErrors={formConfig.country.validationErrors}
            name="country"
            defaultdisplay="Select Country"
            selectableProp="alpha2Code"
            displayProp="name"
            value={user.country}
            options={countries}
          />
          <div className="form-group clearfix">
            <button
              disabled={!this.state.canSubmit}
              className="btn btn-lg btn-primary pull-right"
              type="button"
              onClick={this.submit}
            >
              <Translation id="Update" />
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(UserProfile);
