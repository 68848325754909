import { connect } from "react-redux";

const Translation = props => {
  let translation;

  try {
    translation = props.localization[props.id];
  } catch (error) {
    console.log(`Localization not found for id: ${props.id}`);
  }

  return translation ? translation : props.id;
};

const mapStateToProps = state => {
  return {
    localization: state.localization
  };
};

export default connect(mapStateToProps)(Translation);
// export default Translation;
