import actionTypeRegistry from "../actionTypeRegistry";
import localization_en from "./../../data/localization-en.json";
import localization_rs from "./../../data/localization-rs.json";
import localization_it from "./../../data/localization-it.json";
import localization_de from "./../../data/localization-de.json";
import localization_hu from "./../../data/localization-hu.json";
import localization_no from "./../../data/localization-no.json";

const options = {
  en: localization_en,
  rs: localization_rs,
  it: localization_it,
  de: localization_de,
  hu: localization_hu,
  no: localization_no
};

const setLocalization = (state = localization_en, action) => {
  if (action.type === actionTypeRegistry.localization.SET_LOCALIZATION) {
    return options[action.payload];
  }

  return state;
};

export default setLocalization;
