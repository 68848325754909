import React from "react";
import PropTypes from "prop-types";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";

const DropDown = props => {
  const size = props.bsSize ? props.bsSize : "";
  const onOptionSelected = selectedOption => {
    if (props.onOptionSelected) {
      props.onOptionSelected(selectedOption);
    }
  };

  return (
    <ButtonToolbar>
      <DropdownButton
        bsSize={size}
        title={props.selectedOption[props.displayProp]}
        id={props.selectedOption[props.displayProp]}
      >
        {(() => {
          const options = props.options.map((o, i) => (
            <MenuItem
              key={o.code}
              onClick={() => onOptionSelected(o)}
              eventKey={i}
            >
              {o[props.displayProp]}
            </MenuItem>
          ));
          return options;
        })()}
      </DropdownButton>
    </ButtonToolbar>
  );
};

DropDown.propTypes = {
  bsSize: PropTypes.string,
  displayProp: PropTypes.string,
  onOptionSelected: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  selectedOption: PropTypes.object
};

export default DropDown;
