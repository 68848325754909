import config from "../data/configuration";

class LanguageModel {
  constructor(languageCode, selected) {
    this.code = languageCode ? languageCode : "en";
    this.name = config.languages[this.code].name;
    this.selected = selected ? selected : false;
  }
}

export default LanguageModel;
