import { addValidationRule } from 'formsy-react';

export default {
  init,
  createValidation
};

function createValidation() {
  return new Validation();
}

function init() {
  addValidationRule('isDefined', isDefined);
  addValidationRule('isSpecialCharset', isSpecialCharset);
  addValidationRule('min', min);
  addValidationRule('max', max);
}

function isDefined(values, value) {
  return !!value;
}

function isSpecialCharset(values, value) {
  // only letters (with diacritics), numbers, and several special characters [`'"-] allowed
  return matchRegexp(values, value, /^[A-Z\s\d-\\'\\"\\`\u00C0-\u017F]+$/i);
}

function min(values, value, limit) {
  let _value;

  if (value === undefined) {
    return true;
  }

  try {
    _value = parseInt(value, 10);
  } catch (error) {
    throw new Error('Provided value is not an integer');
  }

  if (!Number.isInteger(_value)) {
    return false;
  }

  if (_value >= limit) {
    return true;
  }

  return false;
}

function max(values, value, limit) {
  let _value;

  if (value === undefined) {
    return true;
  }

  try {
    _value = parseInt(value, 10);
  } catch (error) {
    throw new Error('Provided value is not an integer');
  }

  if (!Number.isInteger(_value)) {
    return false;
  }

  if (_value < limit) {
    return true;
  }

  return false;
}

// uses same implementation as in formsy
function matchRegexp(values, value, regexp) {
  return !_isExisty(value) || isEmpty(value) || regexp.test(value);
}

// uses same implementation as in formsy
function _isExisty(value) {
  return value !== null && value !== undefined;
}

// uses same implementation as in formsy
function isEmpty(value) {
  return value === '';
}

function Validation() {
  this.validations = {};
  this.validationErrors = {};
}

Validation.prototype.addRule = function(name, message, value) {
  if (this.validations[name]) {
    throw new Error(`Validation of the same type (${name}) is already defined`);
  }

  this.validations[name] = value;
  this.validationErrors[name] = message

  return this;
}
