import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import authService from "./../../services/authService";
import { Link, Redirect } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd/dist/antd";

class ResetPasswordRequestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      requestPending: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }

  onSubmit(e) {
    e.preventDefault();
    const data = {
      email: e.target.elements[0].value.trim()
    }
    this.setState({requestPending: true});
    authService
      .createResetPasswordRequest(data)
      .then((res) => {
        alert(`Password Reset E-Mail will be sent to ${data.email}.`);
        this.setState({requestPending: false});
      })
      .catch(err => {
        alert('E-Mail Not Found');
        this.setState({requestPending: false});
      });
  }

  render() {
      // return <Redirect to="/dashboard" />;
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={this.onSubmit}>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Forgot Password?"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Email address</ControlLabel>
                      <FormControl placeholder="Enter email" type="email" />
                    </FormGroup>
                  </div>
                }
                legend={
                  this.state.requestPending ? 
                  <>
                    <Button type="submit" bsStyle="info" fill wd disabled={true}>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </Button>
                    <br/>
                    <Link to="/login" onClick={(e) => e.preventDefault()}>Remember password?</Link>
                  </>
                  :
                  <>
                    <Button type="submit" bsStyle="info" fill wd>
                      Submit
                    </Button>
                      <br/>
                    <Link to="/login">Remember password?</Link>
                  </>

                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default ResetPasswordRequestPage;
