import Dashboard from "views/Dashboard/Dashboard.jsx";
import UserProfile from "./../views/User/profile/UserProfile";
import ChangeLanguage from "./../views/User/settings/changeLanguage/ChangeLanguage";
import ChangePassword from "./../views/User/settings/changePassword/ChangePassword";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard
  },
  {
    collapse: true,
    path: "/user",
    name: "User",
    state: "openUser",
    icon: "pe-7s-plugin",
    views: [
      {
        path: "/user/profile",
        name: "Profile",
        mini: "P",
        component: UserProfile
      },
      {
        path: "/user/language",
        name: "Language",
        mini: "L",
        component: ChangeLanguage
      },
      {
        path: "/user/password",
        name: "Password",
        mini: "P",
        component: ChangePassword
      }
    ]
  },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default dashboardRoutes;
