export default {
  login: "/auth/token",
  me: "/me/",
  configuration: "/configuration/",
  scale: "/scale/",
  measurement: "/measurement",
  password: "/password/",
  createResetPasswordRequest: "/user/reset-password-request/",
  resetPassword: "/user/reset-password/",
};
