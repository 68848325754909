import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AppRoute from "./AppRoute";
import routes from "./appRouteConfiguration";
import "./assets/css/index.css";

const App = () => {
  const _routes = Object.keys(routes).map(r => (
    <AppRoute key={r} {...routes[r]} />
  ));

  if (_routes.length) {
    return (
      <Router>
        <Switch>{_routes}</Switch>
      </Router>
    );
  }

  return null;
};

export default App;
