import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  authService,
  translationService,
  validationService,
  configurationService
} from "./services";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.1.1";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import App from "./App";
import store from "./store/store";

const ROOT_NODE = document.getElementById("root");

authService
  .tryAutoLogin()
  .then(configurationService.init)
  .then(validationService.init)
  .then(translationService.init)
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <App store={store} />
      </Provider>,
      ROOT_NODE
    );
  })
  .catch(error => console.log(error));
