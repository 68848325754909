import TooltipTemplate from "./TooltipTemplate";

export default function priceGraphOptions(measurements, test, timeUnit) {
  return {
    tooltips: {
      callbacks: {
        title: (tooltipItem, chartData) => {
          const index = tooltipItem[0].index;
          return TooltipTemplate(measurements[index], test);
        }
      }
    },
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            parser: "YYY-MM-DD HH:mm",
            unit: timeUnit,
            round: timeUnit,
            tooltipFormat: "ll HH:mm"
          },
          scaleLabel: {
            display: false,
            labelString: "Date"
          }
        }
      ]
    },
  };
};
