class BaseModel {
  constructor(obj) {
    this.id = obj && obj.id ? obj.id : null;
  }

  removeUndefinedProperties() {
    for (let property in this) {
      if (this.hasOwnProperty(property) && this[property] === undefined) {
        delete this[property];
      }
    }

    return this;
  }

  removeEmptyProperties() {
    for (let property in this) {
      if (this.hasOwnProperty(property) && this[property] === "") {
        delete this[property];
      }
    }

    return this;
  }
}

export default BaseModel;
