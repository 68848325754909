import React, { createRef } from "react";
import { connect } from "react-redux";
import { Grid, Row, Col } from "react-bootstrap";
import ScaleDetails from "./ScaleDetails";
import ScaleGraph from "./ScaleGraph";
import { fetchScales, fetchMeasurements } from "./../Scales/scaleService";
import Card from "./../../components/Card/Card";
import moment from "moment";
import { translate } from "../../services/translationService";

class ScaleDetailsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      measurements: [],
      dateRange: []
    };

    this.filterMeasurements = this.filterMeasurements.bind(this);
    this.forecastRef = createRef();
  }

  getDerivedStateFromProps(nextProps, previousState) {
    if (nextProps.scale) {
      return fetchMeasurements(this.props.scale.id, this.state.dateRange).then(m => {
        //const measurements = m.sort((a, b) => a.datetime - b.datetime).slice(-30);
        const measurements = m;
        this.setState({ measurements });
      });
    }
  }

  async filterMeasurements(dateRange) {
    if(!dateRange) dateRange = [];
    dateRange = dateRange.map(date => date.valueOf());
    const mmm = await fetchMeasurements(this.props.scale.id, dateRange);
    //const measurements = mmm.sort((a, b) => a.datetime - b.datetime).slice(-30);
    const measurements = mmm;
    this.setState({ measurements, dateRange });
  }

  async componentDidMount() {
    await this.props.fetchScales();
    await this.filterMeasurements([moment().startOf('month'), moment().endOf('day')]);
  }

  render() {
    const { scale } = this.props;

    if (!scale) {
      return null;
    }
    let messageBox;
    if(!scale.test) {
      let messageBoxArray = this.state.measurements.filter(m => m.messageBox !== null && m.messageBox !== '');
      if(messageBoxArray.length > 0) {
        messageBoxArray.reduce((last, current) => {
          return current.datetime > last.datetime ? current : last
        }, messageBoxArray[0].datetime);
        let contentLines = messageBoxArray[messageBoxArray.length - 1].messageBox.split('\n');
        let content = [];
        for(let i = 0; i < contentLines.length; i++) {
          content.push(<p key={i}>{contentLines[i]}</p>);
        }
        messageBox = <>{content}</>;
      }
    }
    
    return (
      <Grid fluid>
        <Row>
          <Col md={4}>
            <Card content={<ScaleDetails scale={scale} />} />
            {messageBox ? <Card content={messageBox}></Card> : <></>}
            <Card content={
              <>
                <p>{translate("Weather Forecast")}</p>
                <div ref={this.forecastRef}>{translate("Select Location")}</div>
              </>
            }/>
          </Col>
          <Col md={8}>
            <Card
              content={<ScaleGraph forecastRef={this.forecastRef} generateXls={this.generateXls} measurements={this.state.measurements} setDateRange={this.filterMeasurements} test={this.props.scale.test} />}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    scale: state.scales.items.find(
      s => s.id === ownProps.computedMatch.params.id
    )
  };
};

const mapDispatchToProps = {
  fetchScales
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScaleDetailsContainer);
