import languageReducer from "./languageReducer";
import localizationReducer from "./localizationReducer";
import userReducer from "./userReducer";
import routesRegistrationReducer from "./routesRegistrationReducer";
import scaleReducer from "./../../views/Scales/scaleReducer";
import { combineReducers } from "redux";

const reducer = combineReducers({
  language: languageReducer,
  localization: localizationReducer,
  user: userReducer,
  routes: routesRegistrationReducer,
  scales: scaleReducer
});

export default reducer;
