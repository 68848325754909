import React from "react";
import PropTypes from "prop-types";

const BlankLayout = props => <div>{props.children}</div>;

BlankLayout.propTypes = {
  children: PropTypes.object.isRequired
};

export default BlankLayout;
