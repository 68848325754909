import { backend as API } from "./../../services";
import store from "./../../store/store";
import {
  setUserAction,
  setLanguageAction,
  setLocalizationAction
} from "./../../store/actions/allActions";
import UserModel from "./../../models/userModel";

export default {
  getUser,
  changeLanguage,
  changePassword,
  updateUser
};

function changePassword(data) {
  return API.backend({
    method: API.methods.PUT,
    url: API.endpoints.me + API.endpoints.password,
    data
  });
}

function changeLanguage(language) {
  return API.backend({
    method: API.methods.PUT,
    url: API.endpoints.me,
    data: {
      language
    }
  }).then(user => {
    store.dispatch(setLocalizationAction(user.language));
    store.dispatch(setUserAction(user));
  });
}

function getUser() {
  return API.backend({
    method: API.methods.GET,
    url: API.endpoints.me
  }).then(updateStoreWithUser)
    .catch((e) => {
      console.log('Token expired');
    });
}

function updateUser(user) {
  return API.backend({
    method: API.methods.PUT,
    url: API.endpoints.me,
    data: user
  }).then(updateStoreWithUser);
}

function updateStoreWithUser(rawUser) {
  const user = new UserModel(rawUser);

  store.dispatch(setUserAction(user));
  store.dispatch(setLanguageAction(user.language));
  store.dispatch(setLocalizationAction(user.language));

  return user;
}
