export default Object.freeze({
  name: {
    validations: "isDefined",
    validationErrors: {
      isDefined: "Field is missing"
    },
    placeholder: "Enter your name"
  },
  phone: {
    validations: "isDefined",
    validationErrors: {
      isDefined: "Field is missing"
    },
    placeholder: "Enter your phone number"
  },
  address: {
    validations: "isDefined",
    validationErrors: {
      isDefined: "Field is missing"
    },
    placeholder: "Enter your address"
  },
  city: {
    validations: "isDefined",
    validationErrors: {
      isDefined: "Field is missing"
    },
    placeholder: "Enter your City"
  },
  zip: {
    validations: "isDefined",
    validationErrors: {
      isDefined: "Field is missing"
    },
    placeholder: "Enter your ZIP code"
  },
  country: {
    validations: "isDefined",
    validationErrors: {
      isDefined: "Field is missing"
    },
    placeholder: "Select your country"
  }
});
