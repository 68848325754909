// https://restcountries.eu/rest/v2/region/europe?fields=name;alpha2Code;flag

export default [
  {
    flag: '',
    name: 'England',
    alpha2Code: 'ENG',
    fake: true
  },
  {
    flag: '',
    name: 'Wales',
    alpha2Code: 'WAL',
    fake: true
  },
  {
    flag: '',
    name: 'Scotland',
    alpha2Code: 'SCO',
    fake: true
  },
  {
    flag: '',
    name: 'Northern Ireland',
    alpha2Code: 'NIR',
    fake: true
  },
  {
    flag: '',
    name: 'Ireland',
    alpha2Code: 'IRE',
    fake: true
  },
  {
    flag: 'https://restcountries.eu/data/ala.svg',
    name: 'Åland Islands',
    alpha2Code: 'AX'
  },
  {
    flag: 'https://restcountries.eu/data/alb.svg',
    name: 'Albania',
    alpha2Code: 'AL'
  },
  {
    flag: 'https://restcountries.eu/data/and.svg',
    name: 'Andorra',
    alpha2Code: 'AD'
  },
  {
    flag: 'https://restcountries.eu/data/arm.svg',
    name: 'Armenia',
    alpha2Code: 'AM'
  },
  {
    flag: 'https://restcountries.eu/data/aut.svg',
    name: 'Austria',
    alpha2Code: 'AT'
  },
  {
    flag: 'https://restcountries.eu/data/blr.svg',
    name: 'Belarus',
    alpha2Code: 'BY'
  },
  {
    flag: 'https://restcountries.eu/data/bel.svg',
    name: 'Belgium',
    alpha2Code: 'BE'
  },
  {
    flag: 'https://restcountries.eu/data/bih.svg',
    name: 'Bosnia and Herzegovina',
    alpha2Code: 'BA'
  },
  {
    flag: 'https://restcountries.eu/data/bgr.svg',
    name: 'Bulgaria',
    alpha2Code: 'BG'
  },
  {
    flag: 'https://restcountries.eu/data/hrv.svg',
    name: 'Croatia',
    alpha2Code: 'HR'
  },
  {
    flag: 'https://restcountries.eu/data/cyp.svg',
    name: 'Cyprus',
    alpha2Code: 'CY'
  },
  {
    flag: 'https://restcountries.eu/data/cze.svg',
    name: 'Czech Republic',
    alpha2Code: 'CZ'
  },
  {
    flag: 'https://restcountries.eu/data/dnk.svg',
    name: 'Denmark',
    alpha2Code: 'DK'
  },
  {
    flag: 'https://restcountries.eu/data/est.svg',
    name: 'Estonia',
    alpha2Code: 'EE'
  },
  {
    flag: 'https://restcountries.eu/data/fro.svg',
    name: 'Faroe Islands',
    alpha2Code: 'FO'
  },
  {
    flag: 'https://restcountries.eu/data/fin.svg',
    name: 'Finland',
    alpha2Code: 'FI'
  },
  {
    flag: 'https://restcountries.eu/data/fra.svg',
    name: 'France',
    alpha2Code: 'FR'
  },
  {
    flag: 'https://restcountries.eu/data/deu.svg',
    name: 'Germany',
    alpha2Code: 'DE'
  },
  {
    flag: 'https://restcountries.eu/data/gib.svg',
    name: 'Gibraltar',
    alpha2Code: 'GI'
  },
  {
    flag: 'https://restcountries.eu/data/grc.svg',
    name: 'Greece',
    alpha2Code: 'GR'
  },
  {
    flag: 'https://restcountries.eu/data/ggy.svg',
    name: 'Guernsey',
    alpha2Code: 'GG'
  },
  {
    flag: 'https://restcountries.eu/data/vat.svg',
    name: 'Holy See',
    alpha2Code: 'VA'
  },
  {
    flag: 'https://restcountries.eu/data/hun.svg',
    name: 'Hungary',
    alpha2Code: 'HU'
  },
  {
    flag: 'https://restcountries.eu/data/isl.svg',
    name: 'Iceland',
    alpha2Code: 'IS'
  },
  {
    flag: 'https://restcountries.eu/data/irl.svg',
    name: 'Ireland',
    alpha2Code: 'IE'
  },
  {
    flag: 'https://restcountries.eu/data/imn.svg',
    name: 'Isle of Man',
    alpha2Code: 'IM'
  },
  {
    flag: 'https://restcountries.eu/data/isr.svg',
    name: 'Israel',
    alpha2Code: 'IL'
  },
  {
    flag: 'https://restcountries.eu/data/ita.svg',
    name: 'Italy',
    alpha2Code: 'IT'
  },
  {
    flag: 'https://restcountries.eu/data/jey.svg',
    name: 'Jersey',
    alpha2Code: 'JE'
  },
  {
    flag: 'https://restcountries.eu/data/lva.svg',
    name: 'Latvia',
    alpha2Code: 'LV'
  },
  {
    flag: 'https://restcountries.eu/data/lie.svg',
    name: 'Liechtenstein',
    alpha2Code: 'LI'
  },
  {
    flag: 'https://restcountries.eu/data/ltu.svg',
    name: 'Lithuania',
    alpha2Code: 'LT'
  },
  {
    flag: 'https://restcountries.eu/data/lux.svg',
    name: 'Luxembourg',
    alpha2Code: 'LU'
  },
  {
    flag: 'https://restcountries.eu/data/mkd.svg',
    name: 'Macedonia (the former Yugoslav Republic of)',
    alpha2Code: 'MK'
  },
  {
    flag: 'https://restcountries.eu/data/mlt.svg',
    name: 'Malta',
    alpha2Code: 'MT'
  },
  {
    flag: 'https://restcountries.eu/data/mda.svg',
    name: 'Moldova (Republic of)',
    alpha2Code: 'MD'
  },
  {
    flag: 'https://restcountries.eu/data/mco.svg',
    name: 'Monaco',
    alpha2Code: 'MC'
  },
  {
    flag: 'https://restcountries.eu/data/mne.svg',
    name: 'Montenegro',
    alpha2Code: 'ME'
  },
  {
    flag: 'https://restcountries.eu/data/nld.svg',
    name: 'Netherlands',
    alpha2Code: 'NL'
  },
  {
    flag: 'https://restcountries.eu/data/nor.svg',
    name: 'Norway',
    alpha2Code: 'NO'
  },
  {
    flag: 'https://restcountries.eu/data/pol.svg',
    name: 'Poland',
    alpha2Code: 'PL'
  },
  {
    flag: 'https://restcountries.eu/data/prt.svg',
    name: 'Portugal',
    alpha2Code: 'PT'
  },
  {
    flag: 'https://restcountries.eu/data/kos.svg',
    name: 'Republic of Kosovo',
    alpha2Code: 'XK'
  },
  {
    flag: 'https://restcountries.eu/data/rou.svg',
    name: 'Romania',
    alpha2Code: 'RO'
  },
  {
    flag: 'https://restcountries.eu/data/rus.svg',
    name: 'Russian Federation',
    alpha2Code: 'RU'
  },
  {
    flag: 'https://restcountries.eu/data/smr.svg',
    name: 'San Marino',
    alpha2Code: 'SM'
  },
  {
    flag: 'https://restcountries.eu/data/srb.svg',
    name: 'Serbia',
    alpha2Code: 'RS'
  },
  {
    flag: 'https://restcountries.eu/data/svk.svg',
    name: 'Slovakia',
    alpha2Code: 'SK'
  },
  {
    flag: 'https://restcountries.eu/data/svn.svg',
    name: 'Slovenia',
    alpha2Code: 'SI'
  },
  {
    flag: 'https://restcountries.eu/data/esp.svg',
    name: 'Spain',
    alpha2Code: 'ES'
  },
  {
    flag: 'https://restcountries.eu/data/sjm.svg',
    name: 'Svalbard and Jan Mayen',
    alpha2Code: 'SJ'
  },
  {
    flag: 'https://restcountries.eu/data/swe.svg',
    name: 'Sweden',
    alpha2Code: 'SE'
  },
  {
    flag: 'https://restcountries.eu/data/che.svg',
    name: 'Switzerland',
    alpha2Code: 'CH'
  },
  {
    flag: 'https://restcountries.eu/data/ukr.svg',
    name: 'Ukraine',
    alpha2Code: 'UA'
  }
];
