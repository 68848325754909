import SCALE_ACTION_TYPES from "./scaleActionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  items: []
};

const bundlesReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SCALE_ACTION_TYPES.FETCH_SCALES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SCALE_ACTION_TYPES.FETCH_SCALES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case SCALE_ACTION_TYPES.FETCH_SCALES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    default:
      return state;
  }
};

export default bundlesReducer;
