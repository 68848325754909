import axios from "axios";
import endpoints from "./endpoints";
import methods from "./httpMethods";
import errors from "./errors";
import persistanceService from "./../persistanceService";

const baseURL = process.env.REACT_APP_BACKEND_URL;

export default {
  endpoints,
  methods,
  backend
};

class HTTPCall {
  constructor(obj) {
    const ACCESS_TOKEN = persistanceService.get("ACCESS_TOKEN");

    this.method = obj.method || methods.GET;
    this.baseURL = baseURL;
    this.url =
      obj.url && typeof obj.url === "string"
        ? obj.url.replace(/\/\//g, "/")
        : "";
    this.responseType = "json";
    if (ACCESS_TOKEN) {
      this.headers = {
        Authorization: `Bearer ${ACCESS_TOKEN}`
      };
    }
    if (obj.data) {
      this.data = obj.data;
    }
    if (obj.params) {
      this.params = obj.params;
    }
    if (obj.fullUrl) {
      this.url = obj.fullUrl;
    }
  }
}

function backend(params) {
  const callConfig = new HTTPCall(params);

  return axios(callConfig)
    .then(res => {
      if (res.data || res.status === 200) {
        return res.data ? res.data : '';
      }

      throw new Error(errors.MALFORMED_RESPONSE + res.config.url);
    })
    .catch(err => {
      if (isNetworkError(err)) {
        console.log(errors.NETWORK_ERROR);
      }

      if (notAuthorizedError(err)) {
        return redirectToLoginPage();
      }

      return Promise.reject(err);
    });
}

function isNetworkError(error) {
  if (error && error.message === errors.NETWORK_ERROR) {
    return true;
  }

  return false;
}

function notAuthorizedError(error) {
  if (error && error.response && error.response.status === 401) {
    return true;
  }

  return false;
}

function redirectToLoginPage() {
  // obrisati lose tokeMe
  persistanceService.remove("ACCESS_TOKEN");
  window.location.href = window.location.origin + "/login";
  return;
}
