import React, { Component } from "react";
import { Nav, NavDropdown, MenuItem } from "react-bootstrap";
import Translation from "../Translation";
import authService from "./../../services/authService";

class HeaderLinks extends Component {
  render() {
    return (
      <div>
        <Nav pullRight>
          <NavDropdown
            eventKey={4}
            title={
              <div>
                <i className="fa fa-list" />
                <p className="hidden-md hidden-lg">
                  <Translation id="More" />
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown"
          >
            <MenuItem eventKey={4.2}>
              <i className="pe-7s-help1" /> <Translation id="Help Center" />
            </MenuItem>
            <MenuItem divider />
            <MenuItem onClick={authService.logout} eventKey={4.5}>
              <div className="text-danger">
                <i className="pe-7s-close-circle" />{" "}
                <Translation id="Log out" />
              </div>
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}
export default HeaderLinks;
