import React, { Component } from "react";
import PagesHeader from "components/Header/PagesHeader.jsx";
import bgImage from "assets/img/full-screen-image-4.jpg";
// import { Switch, Route } from "react-router-dom";
// import Footer from "components/Footer/Footer.jsx";
// dinamically create pages routes
// import pagesRoutes from "routes/pages.jsx";

class Pages extends Component {
  getPageClass() {
    var pageClass = "";
    const pathname = this.props.children.props.location.pathname;
    if(pathname.startsWith('/reset-password')) {
      console.log(pathname);
      pageClass = " login-page";
      return pageClass;
    }
    switch (this.props.children.props.location.pathname) {
      case "/login":
        pageClass = " login-page";
        break;
      case "/pages/register-page":
        pageClass = " register-page";
        break;
      case "/pages/lock-screen-page":
        pageClass = " lock-page";
        break;
      default:
        pageClass = "";
        break;
    }
    return pageClass;
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  render() {
    return (
      <div>
        <PagesHeader />
        <div className="wrapper wrapper-full-page">
          <div
            className={"full-page" + this.getPageClass()}
            data-color="black"
            data-image={bgImage}
          >
            <div className="content">{this.props.children}</div>
            <div
              className="full-page-background"
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Pages;
