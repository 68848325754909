import actions from "./../actionTypeRegistry";
import LanguageModel from "./../../models/languageModel";

export const setLanguageAction = languageCode => {
  return {
    type: actions.language.SET_LANGUAGE,
    payload: new LanguageModel(languageCode, true)
  };
};

export const setLanguageOptionAction = option => {
  return {
    type: actions.language.SET_LANGUAGE_OPTION,
    payload: option
  };
};

export const markLanguageOptionSelectedAction = languageCode => {
  return {
    type: actions.language.SELECT_LANGUAGE_OPTION,
    payload: {
      code: languageCode
    }
  };
};
