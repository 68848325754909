import actions from "./../actionTypeRegistry";

export default userReducer;

function userReducer(state = null, action) {
  if (action.type === actions.user.USER_LOGGED_IN) {
    return action.payload;
  }

  return state;
}
