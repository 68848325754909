import React from "react";
import { Route, Redirect } from "react-router-dom";
import StandardLayout from "./layouts/Dashboard/Dashboard";
import LoginLayout from "./layouts/Pages/Pages";
import BlankLayout from "./layouts/Blank/BlankLayout";
import { connect } from "react-redux";

const layouts = {
  blank: BlankLayout,
  login: LoginLayout,
  standard: StandardLayout
};

class AppRoute extends React.Component {
  render() {
    const {
      component,
      layout,
      path,
      isLogedIn,
      authorized,
      ...rest
    } = this.props;
    const Layout = layouts[layout];
    const Component = component;

    if (authorized && !isLogedIn) {
      return <Redirect to="/login" />;
    }

    if (path === "/") {
      return <Redirect to="/dashboard" />;
    }

    return (
      <Route
        {...rest}
        render={() => (
          <Layout title={""}>
            <Component {...this.props} />
          </Layout>
        )}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isLogedIn: !!state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(AppRoute);
